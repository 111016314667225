export function getURLValues(searchParams) {
    return {
        URLSearchValue: searchParams.get('search'),
        URLFieldValue: searchParams.get('field'),
        URLSortValue: searchParams.get('sort'),
        URLPageValue: searchParams.get('page')
    }
}

export function validateURLValues(searchParams, columns) {
    const { URLSearchValue, URLFieldValue, URLSortValue, URLPageValue } = getURLValues(searchParams)
    const URLFieldValueValid = URLFieldValue && columns.map(column => column.field).includes(URLFieldValue)
    const URLSortValueValid = URLSortValue && ['asc', 'desc'].includes(URLSortValue)
    const URLPageValueValid = URLPageValue && typeof Number(URLPageValue) === 'number' && Number(URLPageValue) >= 1
    return { URLSearchValueValid: URLSearchValue, URLFieldValueValid, URLSortValueValid, URLPageValueValid }
}

export function updateSearchParams(searchParams, columns) {
    const { URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid } = validateURLValues(searchParams, columns)
    if (!URLSearchValueValid) searchParams.delete('search')
    if (!URLFieldValueValid || !URLSortValueValid) {
        searchParams.delete('field')
        searchParams.delete('sort')
    }
    if (!URLPageValueValid) searchParams.delete('page')
}

export function getValidURLParams(searchParams, columns, tableToolbarProps) {
    if (!tableToolbarProps.mainTable) {
        return {
            "defaultPaginationModel": { pageIndex: 0, pageSize: 10 },
            "defaultSortModel": columns?.length > 0 ? [{ field: columns[0].field, sort: 'asc' }] : [],
            "defaultQueryOptions": {}
        }
    }
    const { URLSearchValue, URLFieldValue, URLSortValue, URLPageValue } = getURLValues(searchParams)
    const { URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid } = validateURLValues(searchParams, columns)
    const defaultQueryOptions = URLSearchValueValid ? { search_value: URLSearchValue } : { search_value: '' }
    const defaultSortModel = URLFieldValueValid && URLSortValueValid ? [{ field: URLFieldValue, sort: URLSortValue }] : [{ field: columns[0].field, sort: 'asc' }]
    const defaultPaginationModel = URLPageValueValid ? { pageIndex: Number(URLPageValue) - 1, pageSize: 10 } : { pageIndex: 0, pageSize: 10 }
    
    return { defaultQueryOptions, defaultSortModel, defaultPaginationModel }
}