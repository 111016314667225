import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TableBody from './TableBody'
import { useEndpointProvider } from '../../Providers/EndpointProvider'
import { useOnMount } from '../../Hooks/useOnMount'
import CustomPagination from '../MUITable/CustomPagination'
import { Input, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useMobergTheme } from '../../Providers/MobergThemeProvider'
import { useBackendLinksProvider } from '../../Providers/BackendLinksProvider'
import { useNavigate, useLocation } from 'react-router-dom';
import { validateURLValues, updateSearchParams, getValidURLParams } from '../MUITable/SearchQueryUtils'

function FileDirectoryTable(props) {
    const { LINKS } = useBackendLinksProvider()

    const {
        columns,
        endpoint = LINKS.ADMIN.SITES.GET_SITES,
        body = {},
        optionalParams,
        patient, 
        tableToolbarProps
    } = props
    const endpointProvider = useEndpointProvider()
    const theme = useMobergTheme()
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const { defaultSortModel, defaultPaginationModel, defaultQueryOptions } = getValidURLParams(searchParams, columns, tableToolbarProps)

    const [queryOptions, setQueryOptions] = useState(defaultQueryOptions)
    const [paginationModel, setPaginationModel] = useState(defaultPaginationModel)
    const [sortModel, setSortModel] = useState(defaultSortModel)

    const [parentLevelData, setParentLevelData] = useState([])
    const [childLevelData, setChildLevelData] = useState({})
    const [numUnfetchedChildData, setNumUnfetchedChildData] = useState({})
    const [currentPath, setCurrentPath] = useState(body.patient_path)
    const [isFetching, setIsFetching] = useState({})
    const [totalRowCount, setTotalRowCount] = useState(0)
    const [prevID, setPrevID] = useState()
    const [totalFiles, setTotalfiles] = useState({})
	const urlSearchValue = searchParams.get("search") || ""
    const [searchValue, setSearchValue] = useState("")

    const queryKeyObject = {
        ...body,
        ...optionalParams,
        page: paginationModel.pageIndex,
        filters: queryOptions,
        sort: sortModel,
        patient_path: currentPath
    }

    function handleSortModel(sortModel) {
		if (sortModel.length === 0) {
			searchParams.delete('field')
			searchParams.delete('sort')
		} else {
			searchParams.set('field', sortModel[0]['field'])
			searchParams.set('sort', sortModel[0]['sort'])
		}
		handleNavigate()
        setSortModel(sortModel)
	}

    function handleNavigate() {
		navigate(`${location.pathname}?${searchParams.toString()}`);
	}

    const handleInputOnChange = (e) => {
		const newValue = e.target.value;
		if (tableToolbarProps.mainTable) {
            if (newValue) {
				searchParams.set('search', newValue);
			} else {
				searchParams.delete('search');
			}
			handleNavigate()
		}
		setSearchValue(newValue)
		setQueryOptions({ ...queryOptions, search_value: newValue })
	}

    async function getData() {
        const endpointData = await endpointProvider.post(endpoint, queryKeyObject, optionalParams)

        endpointData?.data.forEach(row => {
            if (row.type === 'directory') {
                row.expanded = false
            }
        })
        setParentLevelData(endpointData?.data)
        setTotalRowCount(endpointData?.total)
    }

    const [currentPage, setCurrentPage] = useState(0)
    const [prevCallGetMore, setPrevCallGetMore] = useState(false)

    async function getChildData(path, getMore = false) {
        setIsFetching((prev) => ({ ...prev, [path]: true }))

        if (prevCallGetMore !== getMore) {
            setCurrentPage(0)
        }

        if (getMore) {
            queryKeyObject.page = currentPage + 1
            setCurrentPage(currentPage + 1)
            setPrevCallGetMore(true)
        } else {
            setPrevCallGetMore(false)
        }

        queryKeyObject.patient_path = path
        const endpointData = await endpointProvider.post(endpoint, queryKeyObject, optionalParams)

        endpointData?.data.forEach(row => {
            if (row.type === 'directory') {
                row.expanded = false
            }
        })

        if (getMore) {
            setChildLevelData(prev => {
                const existingData = structuredClone(prev)
                if (!Object.keys(existingData).includes(path)) {
                    existingData[path] = endpointData?.data
                } else {
                    existingData[path].push(...endpointData?.data)
                }

                return existingData
            })
        } else {
            setChildLevelData(prev => {
                const existingData = structuredClone(prev)
                existingData[path] = endpointData?.data
                return existingData
            })
        }

        setNumUnfetchedChildData(prev => {
            const existingData = structuredClone(prev)
            existingData[path] = endpointData?.files_left
            return existingData
        })

        setIsFetching((prev) => ({ ...prev, [path]: false }))
        setTotalfiles((prev) => {
            const prevData = structuredClone(prev)
            prevData[path] = endpointData?.total
            return prevData
        })
    }

    useOnMount(() => {
        getData()

        if (!tableToolbarProps.mainTable) return
		const { URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid } = validateURLValues(searchParams, columns)
		const allValid = [URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid].every(value => value || value === null)
		if (allValid) return
		updateSearchParams(searchParams, columns)
		handleNavigate()
    })

    async function navigateIntoDirectory(row) {
        queryKeyObject.patient_path = row.path
        setCurrentPath(row.path)
        await getData()
        setChildLevelData({})
        setPrevID(undefined)
    }

    useEffect(() => {
        getData()
    }, [sortModel, paginationModel, queryOptions])

    useEffect(() => {
		// Wait for data to come back before checking validation.
		if (isFetching && totalRowCount === 0) {
			return
		}
		validateAndNavigateToCorrectPage()
	})

    function validateAndNavigateToCorrectPage() {
		// only can be checked after data is finished fetching!!
		let pageIndex = paginationModel.pageIndex
		const maxPageIndex = totalRowCount !== 0 ? Math.ceil(totalRowCount / 10) - 1 : 0
		if (pageIndex > maxPageIndex) {
			pageIndex = Math.max(0, Math.min(pageIndex, maxPageIndex))
			searchParams.set('page', pageIndex + 1)
			setPaginationModel(previous => ({ ...previous, pageIndex: pageIndex }))
			handleNavigate()
		}
	}

    function BuildCrumbs() {
        return (
            <>
                <LinkButton id='TopLevel' color={theme.colors.link.main} onClick={() => {
                    queryKeyObject.patient_path = '';
                    setChildLevelData({})
                    getData()
                    setCurrentPath('')
                }}>
                    {`${patient?.site_name} / Patient ${patient?.patient_id}`}
                </LinkButton>
                <>
                    {currentPath.split('/').map((path, index, paths) => {
                        if (path !== '') {
                            const current = paths.length - 1 === index;
                            const destination = paths.slice(0, index + 1).join('/');
                            const id = `Go To: ${destination}`
                            return (
                                <LinkButton key={id} id={id} color={theme.colors.link.main} onClick={() => {
                                    queryKeyObject.patient_path = destination;
                                    setChildLevelData({});
                                    getData();
                                    setCurrentPath(destination);
                                }} style={{ textDecoration: current ? 'none' : 'underline', color: current ? 'black' : '#069', fontWeight: current ? 600 : 'unset', pointerEvents: current ? 'none' : 'auto' }}>
                                    &nbsp;{`/ ${path}`}
                                </LinkButton>
                            );
                        }
                        return null
                    })}
                </>
            </>
        );
    }

    const ColumnVisbilityMenuRef = useRef()

    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.field))

    return (
        <FileDirectoryTableContainer id='FileDirectoryTable' style={{ marginTop: '10px' }}>
            <div style={{ alignSelf: 'start' }}>
                {BuildCrumbs()}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', background: "white", gap: "8px", padding: '4px 8px' }}>
                <div style={{ flex: 1 }}>
                    <Input
                        id='MUI-Search-Bar'
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        placeholder="Search"
                        value={tableToolbarProps?.mainTable ? urlSearchValue : searchValue}
                        onChange={handleInputOnChange}
                        disableUnderline={true}
                        sx={{ margin: '8px 0px 10px' }}
                    />
                </div>
            </div>

            <TableBody columns={columns} rows={parentLevelData} childLevelData={childLevelData} navigateIntoDirectory={navigateIntoDirectory} getChildData={getChildData} numUnfetchedChildData={numUnfetchedChildData} isFetching={isFetching} sortModel={sortModel} setSortModel={handleSortModel} prevID={prevID} setPrevID={setPrevID} ColumnVisbilityMenuRef={ColumnVisbilityMenuRef} totalFiles={totalFiles} visibleColumns={visibleColumns} patient={patient} />

            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '18px' }}>
                <CustomPagination paginationModel={paginationModel} setPaginationModel={setPaginationModel} totalRowCount={totalRowCount} handleNavigate={handleNavigate} searchParams={searchParams} mainTable={true}/>
            </div>

        </FileDirectoryTableContainer>
    )
}

export default FileDirectoryTable

const FileDirectoryTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::-webkit-scrollbar {
        display: block;
        width: 5px;
        color: #313A4A;
    }
    ::-webkit-scrollbar-track {
        background: #BEC4CF;
        width: 5px;
        border-radius: 2px;
    }
`

const LinkButton = styled.button`
	background: none !important;
	border: none;
	padding: 0 !important;
	font-family: arial, sans-serif;
	color: ${props => props.color};
	text-decoration: underline;
	cursor: pointer;
`