import { useEffect, useState, useMemo } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useGridApiRef } from "@mui/x-data-grid"
import { useFetchTableData } from "../../Hooks/useFetchTableData"
import CustomPagination from "./CustomPagination"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import TableToolbar from "./TableToolbar"
import FilterPanel from "./FilterPanel"
import { usePopover } from "./usePopover"
import { useNavigate, useLocation } from 'react-router-dom';
import { validateURLValues, updateSearchParams, getValidURLParams } from './SearchQueryUtils'

export default function MUITable(props) {

	const {
		columns,
		tableToolbarProps: incomingToolbarProps = {},
		preselectedProps = null,
		queryKey ,
		endpoint,
		body = {},
		optionalParams,
		disableMultiSelect = null,
		isCheckboxSelection = true,
		tableData,
		dense = false,
		disableRowSelectionOnClick = false,
		isRowSelectable = () => true,
		disableSelectAll = false,
		tableRef = undefined,
		disableHighlightRows = false,
		isAutoselect = false,
		onRowSelectionModelChange = () => { },
	} = props

	const defaultToolbarProps = {
        createButton: { isShow: false },
        deleteButton: { isShow: false },
        viewMoreButton: { isShow: false },
        viewDomainsButton: { isShow: false },
        filterButton: { isShow: false },
        exportButton: { isShow: false },
        searchInput: { isShow: true },
        columnButton: { isShow: false },
        nameForMultipleRows: "rows",
    };

	const tableToolbarProps = { ...defaultToolbarProps, ...incomingToolbarProps };

	// add id to each object in tableData
	if (tableData) {
		tableData.forEach((row, index) => {
			if (row.directoryName) {
				row.id = row.directoryName
			} else {
				row.id = index
			}
		})
	}

	const apiRef = useGridApiRef()
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const { defaultSortModel, defaultPaginationModel, defaultQueryOptions } = getValidURLParams(searchParams, columns, tableToolbarProps)

	const [queryOptions, setQueryOptions] = useState(defaultQueryOptions)
	const [paginationModel, setPaginationModel] = useState(defaultPaginationModel)
	const [sortModel, setSortModel] = useState(defaultSortModel)

	useEffect(() => {
		if (!tableToolbarProps.mainTable) return

		const { URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid } = validateURLValues(searchParams, columns)
		const allValid = [URLSearchValueValid, URLFieldValueValid, URLSortValueValid, URLPageValueValid].every(
							value => value || value === null
						) && ( 
							(URLFieldValueValid && URLSortValueValid) || 
							(URLFieldValueValid === null && URLSortValueValid === null)
						);
		if (allValid) return
		updateSearchParams(searchParams, columns)
		handleNavigate()
	})

	function handleSortModel(sortModel) {
		if (tableToolbarProps.mainTable) {
			if (sortModel.length === 0) {
				searchParams.delete('field')
				searchParams.delete('sort')
			} else {
				searchParams.set('field', sortModel[0]['field'])
				searchParams.set('sort', sortModel[0]['sort'])
			}
			handleNavigate()
		}
		setSortModel(sortModel)
	}

	function handleNavigate() {
		navigate(`${location.pathname}?${searchParams.toString()}`);
	}

	const { data, isFetching, isError, refetch } = useFetchTableData({
		queryKey,
		endpoint,
		body,
		optionalParams,
		queryOptions,
		paginationModel,
		sortModel,
	})

	const rows = useMemo(() => tableData || data?.data || [], [data?.data, tableData])
	const rowCount = tableData ? tableData.length : data?.total || 0
	const gridHeight = rows.length === 0 ? "30vh" : "100%"
	const [rowSelectionModel, setRowSelectionModel] = useState(preselectedProps ? preselectedProps.selectedRows : [])

	useEffect(() => {
		if (!preselectedProps) return
		setRowSelectionModel(preselectedProps?.selectedRows)
	}, [preselectedProps, preselectedProps?.selectedRows])

	useEffect(() => {
		if (isAutoselect && rows.length === 1 && rowSelectionModel.length === 0) {
			const autoSelectedRows = [rows[0].id]

			setRowSelectionModel(autoSelectedRows)
			preselectedProps?.setSelectedRows(autoSelectedRows)

			disableMultiSelect(
				rows[0],
				true
			)
		}
	}, [disableMultiSelect, isAutoselect, preselectedProps, rowSelectionModel.length, rows])

	useEffect(() => {
		// Wait for data to come back before checking validation.
		if (isFetching && rowCount === 0) {
			return
		}

		validateAndNavigateToCorrectPage()
	})

	function validateAndNavigateToCorrectPage() {
		// only can be checked after data is finished fetching!!
		let pageIndex = paginationModel.pageIndex
		const maxPageIndex = rowCount !== 0 ? Math.ceil(rowCount / 10) - 1 : 0

		if (pageIndex > maxPageIndex) {
			pageIndex = Math.max(0, Math.min(pageIndex, maxPageIndex))
			searchParams.set('page', pageIndex + 1)
			setPaginationModel(previous => ({ ...previous, pageIndex: pageIndex }))
			handleNavigate()
		}
	}

	const { isOpen: isFilterPanelOpen, anchorEl: filterPanelAnchor, open: openFilterPanel, close: closeFilterPanel, setPopoverRef: setFilterPopoverRef } = usePopover()
	const { isOpen: isColumnsPanelOpen, anchorEl: columnsPanelAnchor, open: openColumnsPanel, setPopoverRef: setColumnsPopoverRef } = usePopover()
	const { isOpen: isViewMorePanelOpen, anchorEl: viewMorePanelAnchor, open: openViewMorePanel, setPopoverRef: setViewMorePopoverRef, close: closeViewMorePopover } = usePopover()

	if (isError) return <ErrorMessage refetch={refetch} />

	const overrideStyles = {}

	if (disableHighlightRows) {
		overrideStyles['& .MuiDataGrid-row:hover'] = { background: "white" }
		overrideStyles['& .MuiDataGrid-row:hover .MuiDataGrid-cell'] = { background: "white", color: "#293241"}
	}

	return (
		<div id={`MUI-Table`} style={{ width: "100%", display: "flex", justifyContent: "center", background: '#F8F8F8', borderRadius: "6px", padding: "30px", ...props.style }}>
			<div style={{ height: gridHeight, width: "100%" }}>
				<DataGrid
					apiRef={tableRef ? tableRef : apiRef}
					sx={overrideStyles}
					// columns and rows
					columns={columns}
					rows={rows}
					rowHeight={!dense ? 40 : undefined}
					// onRowDoubleClick={() => { console.log('DOUBLE CLICK') }}
					// autoPageSize

					// selection
					isRowSelectable={isRowSelectable}
					checkboxSelection={isCheckboxSelection}
					disableSelectAll={disableSelectAll}
					onRowSelectionModelChange={newRowSelectionModel => {
						if (disableMultiSelect) {
							if (newRowSelectionModel.length > 1) {
								const lastSelected = newRowSelectionModel[newRowSelectionModel.length - 1]
								const newSelection = [lastSelected]
								setRowSelectionModel(newSelection)
								preselectedProps?.setSelectedRows(newSelection)
							} else {
								setRowSelectionModel(newRowSelectionModel)
								preselectedProps?.setSelectedRows(newRowSelectionModel)
							}
							if (newRowSelectionModel.length > 0) {
								disableMultiSelect(
									rows.find(row => row.id === newRowSelectionModel[newRowSelectionModel.length - 1]),
									true
								)
							} else {
								disableMultiSelect({}, false)
							}
						} else {
							setRowSelectionModel(newRowSelectionModel)
							preselectedProps?.setSelectedRows(newRowSelectionModel)
						}
						onRowSelectionModelChange(newRowSelectionModel)
					}}
					rowSelectionModel={rowSelectionModel}
					disableRowSelectionOnClick={disableRowSelectionOnClick}
					keepNonExistentRowsSelected
					loading={isFetching}
					sortingMode="server"
					onSortModelChange={handleSortModel}
					paginationMode="server"
					rowCount={rowCount}
					// MUI requires paginationModel to have page, but that's confusing. We use pageIndex, but convert it to page before sending paginationModel to MUI
					paginationModel={{...paginationModel, page: paginationModel.pageIndex}}
					// custom components
					slots={{
						toolbar: TableToolbar,
						pagination: CustomPagination,
						columnsPanel: () => null,
					}}
					slotProps={{
						toolbar: {
							...tableToolbarProps,
							openFilterPanel,
							setColumnsPopoverRef,
							setViewMorePopoverRef,
							columnsPanelAnchor,
							viewMorePanelAnchor,
							openColumnsPanel,
							openViewMorePanel,
							isColumnsPanelOpen,
							isViewMorePanelOpen,
							rowSelectionModel,
							setRowSelectionModel,
							setQueryOptions,
							closeViewMorePopover
						},
						pagination: {
							paginationModel: paginationModel,
							setPaginationModel: setPaginationModel,
							totalRowCount: rowCount,
							handleNavigate: handleNavigate,
							searchParams: searchParams,
							mainTable: tableToolbarProps.mainTable
						},
						panel: {
							sx: {
								'& .MuiDataGrid-columnsPanelRow:first-of-type': {
									display: isCheckboxSelection ? 'none' : 'flex',
								},
							},
						},
						columnsPanel: {
							disableHideAllButton: true,
						}
					}}
					density={dense ? 'compact' : 'standard'}
					getRowClassName={(params) => {
						return params.row.type === "directory" ? "directory" : ""
					}}
					initialState={{
						sorting: {
							sortModel: sortModel
						}
					}}
					disableColumnMenu={true}
				/>
			</div>
			<FilterPanel setRef={setFilterPopoverRef} open={isFilterPanelOpen} onClose={closeFilterPanel} anchorEl={filterPanelAnchor} />
		</div>
	)
}
